//fonts
@font-face {
	font-family: 'circular_stdblack';
	src: url('/fonts/circular/circularstd-black-webfont.woff2') format('woff2'),
		 url('/fonts/circular/circularstd-black-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
  }
  @font-face {
	font-family: 'circular_stdbook';
	src: url('/fonts/circular/circularstd-book-webfont.woff2') format('woff2'),
		 url('/fonts/circular/circularstd-book-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// General
body {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'circular_stdbook' !important;
	font-weight: 400;
	padding-bottom: 20px;
}
html, body, #root {
    -webkit-touch-callout: auto;
    -webkit-user-select: auto;
    user-select: auto;
}
button span {
	box-sizing: content-box;
}

.no-margin {
	margin: 0 !important;
}

// Remove Shadow from Baseui App Nav bar... doesn't offer overrides yet...
#callsesh-header > div {
	box-shadow: none !important;
}

// Router Progress
#nprogress {
	.spinner {
		display: none !important;
	}
	.bar {
		background: #000000 !important;
		z-index: 999999999;
	}
}

// Uploadcare widget
.uploadcare--widget__button {
	cursor: pointer;
}

// Error pages
.callsesh-error-container > div {
	height: auto !important;
}

#call-mute { text-align: center }

iframe#webpack-dev-server-client-overlay {
    display: none;
}
.receiverlink{
 color: white;
}
// Device Phone Mockup
.device .device-frame {
	background: white;
	box-shadow: inset 0 0 2px 2px black, inset 0 0 0 6px black;
	& img {
		max-width: 100%;
		-webkit-font-smoothing: antialised;
		backface-visibility: hidden;
		transform: translateZ(0);
		-webkit-font-smoothing: subpixel-antialiased;
		min-width: 82px;
	}
}
@media only screen and (max-width : 1000px){
	.titleContent span sup {
		font-size: 20px;
	}
	.device .device-frame img {
		max-width: 100px !important;
	}
	.device-iphone-x {
		height: 100vh;
		width: 400px;
		text-align: center;
		.device-frame {
			height: 100%;
			width: 100%;
			position: relative;
			border-radius: 0;
			box-shadow: none;
			.title {
				top: 12%;
				right: 0;
				left: 0;
				margin: 0 auto;
			}
		}
	}
}
.device-iphone-x .device-header {
	top: 6px;
}

.device-iphone-x .device-sensors::before{
	top: 14px;
}

.device-iphone-x .device-sensors::after{
	top: 17px;
}

.title{
	font-family: 'circular_stdbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.01px;
	color: #000000;
	width:85%;
  	display: flex;
  	justify-content: center;
	position: absolute;
	top: 10%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.body{
	width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-items: center;
    margin-top: 55%;
    align-content: center;
    height: 100px;
	width: 88%;
	@media only screen and (max-width : 1000px){
		margin-top: 35%;
	}
}

.bodyPart{
	width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    align-items: center;
    top: 50%;
    align-content: center;
    left: 0%;
	right: 0;
	margin: 0 auto;
	font-family: 'circular_stdbook';
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.01px;
	color: #000000;
	&:before {
		content: "Call reason";
		position: absolute;
		right: 0;
		left: 0;
		margin: 0 auto;
		top: 25px;
		color: #858B97;
		font-size: 12px;
	}
}

.footer{
	width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    top: 70%;
    align-content: center;
    height: 60px;
	@media only screen and (max-width : 1000px){
		width: 90%;
	}
}
.footerafter{
	width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    top: 80%;
    align-content: center;
    height: 60px;
	@media only screen and (max-width : 1000px){
		top: 75%;
		height: auto;
		left: 13%;
	}
}
.accept{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 20%;
	cursor: pointer;
}

.decline{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 200px;
	cursor: pointer;
}
.end{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 220px;
	cursor: pointer;
}
.mute{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 143px;
	cursor: pointer;
}
.speaker{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 72px;
	cursor: not-allowed;
}
.bluetooth{
	position: absolute;
	width: 70px;
	height: 70px;
	left: 05px;
	cursor: not-allowed;
}
.bottom{
	position: absolute;
	top:89%;
	left: 15%;
}
#sc-sdk #sc__sdk {
    position: absolute;
    width: 100%;
	top: 0;
    border-radius: 10px;    
	padding: 20px;
	height: 320px;
	#sc__sdk--header {
		margin: 0px 0 10px 0;
	}
	#sc__ong {
		#sc__mute_container {
			display: inline-block;
		}
		#sc__ong--hangup--container {
			display: inline-block;
			margin-left: 20px;
		}
	}
}
.poweredby{
	position: absolute;
	left:25%;
	right:0;
	top:630px;
	font-family: 'circular_stdbook';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.01px;
	color: #000000;
	display: inline-flex;
	sup {
		font-size: 9px !important;
		position: relative;
		top: -5px;
		margin-left: 2px;
	}
	@media only screen and (max-width : 1000px){
		left:30%;
	}
}
.bodyPart p {
    background: rgba(255, 255, 255, 1);
    padding: 25px 12px 12px 12px;
    border-radius: 6px;
    box-shadow: 0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25);
    min-height: 50px;
	line-height: 1.3;
	font-size: 18px;
	width: 100%;
    word-wrap: break-word;
	@media only screen and (max-width : 1000px){
		line-height: 1.3;
		font-size: 15px;
	}
}
.brandname{
	font-family: 'circular_stdbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.01px;
	color: #000000;
	margin: 15px;
}
.time{
	font-family: 'circular_stdbook';
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 16px;
	text-align: center;
	letter-spacing: -0.01px;
	color: #000000;
	margin-top: 20px;
}
.buttonNav{
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: start;
    margin-top: 5%;
    margin-left: 3px;
	@media only screen and (max-width : 620px){
		margin-left: 0%;
	  }


}

.parentperson{
	display: inline-block;
    flex-direction: column;
	@media only screen and (max-width : 620px){
		align-content: center;
		position: relative;
		flex-wrap: wrap;
		justify-content: space-between;
		display: block;
	  }

}
.hexchild{
	display: flex;
    flex-direction: column;
}
.btn{
	margin-left: 5%;
  }

.imgurl span{
	font-size: 0.8rem;
}

.context{
	display: flex;
    flex-direction: column;
}
.modal{
	position: absolute;
	right: 0px;
}
.sharemodal{
	z-index: 2;
	width: 500px;
	height: 200px;
	background-color: aliceblue;
}


// Drawer
[data-baseweb="drawer"] {
	z-index: 99999;
}

.container_new {
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: pre;
	margin-bottom: 1.5rem;
	font-size: 2em;
	font-weight: 800;
	@media only screen and (max-width : 620px){
		font-size: 1.2em;
		letter-spacing: -1px;
		margin-left: 0px;
		
	  }
	/* letter-spacing: -4px; */
  }
  
  .code-snippet {
	width: 800px;
	display: flex;
	flex-wrap: nowrap;
	word-wrap: break-word;
	// background: #ffcc6d;
	background: #272730;
	padding: 0.1rem;
	@media only screen and (max-width : 620px){
		width: 350px;
		padding: 0%;
	  }
	
  }
  
  
  .code-section {
	position: relative;
	width: 100%;
  }
  
  .code-section pre {
	font-size: 80%;
  }
  
  .code-section span {
	position: absolute;
	right: 0;
	top: 5%;
	bottom: 0;
	font-size: 120%;
	@media only screen and (max-width : 620px){
		left: 290px;
	  }
	
  }
  .code-section p{
	font-size: 90%;
	
  }
  
  .personalNav{
	display:flex;
	flex-direction:row;
	align-items:flex-end;
	justify-content:space-evenly;
	@media only screen and (max-width : 620px){
		flex-direction: column;
		justify-content: center;
		width: 100%;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
	  }
  }

  .imgurl{
    display:flex;
    flex-direction : column;
  }

  .popover{
    position: absolute;
    z-index: 2;
	bottom: 12%;
    right: 9.2%;
	@media only screen and (max-width : 620px){
		left: 50px;
	  }
  }
  .enterNav{
	@media only screen and (max-width : 620px){
		height: 10% !important;
	  	top:70% !important;
	  }
  }

// Accordion
.accordion-panel-content {
	> p {
		&:first-child {
			margin-top: 0px;
		}
		&:last-child {
			margin-bottom: 0px;
		}
	}
}

// Device Slider
.device .slick-slider {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px !important;
	mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
	-webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);

	.slick-list {
		width: 100%;
		flex-grow: 1;
	}

	.slick-track > div {
		display: inline-block !important;
	}

	.slick-slide div {
		outline: 0 !important;
	}

	.slick-arrow {
		display: none !important;
	}
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
//   visibility: hidden;
//   opacity: 0;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  top: 30%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;
  text-align: left;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  margin-bottom: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06a7d8;
}
.content{
	display: flex;
    flex-direction: column; 
}
.content button{
    margin: 15px;
}
.popup .content {
  max-height: 40%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}

.icons{
	padding-top: 20px;
    padding-bottom: 15px;
    text-align: left;
}
.copytext{
	position: relative;
    padding: 14px;
    color: #263238;
    cursor: text;
    display: inline-flex;
    font-size: 14px;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    letter-spacing: -.05px;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    background: #000;
    border-radius: 4px;
    border: none;
}
.codesnippet{
	color: #fff;
    max-width: calc(100% - 25px);
    overflow-x: auto;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
}
.copybtn{
	width: auto;
    position: absolute;
	color: white;
    right: 0;
	font-size: 180%;
    font-weight: 700;
}
.input{
	font-size: 15px;
	padding: 10px;
	margin: 0 0 10px 0;
	border: 0.5px solid;
	border-radius: 3px;
	border-color: rgba(221, 221, 221, 1);
	width: 100%;
}

.productChoice{
	display: flex;
	justify-content: space-around;
}

.businessChoice{
	display: flex;
	justify-content: space-evenly;
}

.roleChoice{
	display: flex;
	justify-content: space-evenly;
}

.breadcrumb{
	display: flex;
	z-index: 5000;
	justify-content: flex-start;
	flex-wrap: wrap;
	@media only screen and (max-width : 1000px){
		position: relative;
		left: -10%;
		overflow: scroll;
	  }
}

ol {
	list-style: none;
	display: flex;
	align-items: center;
	z-index: 5000;
  }

  .note{
	font-size: 0.4em,
  }

  .linkContext {
	font-size: 0.4em;
  }

  .startBtn{
	transition: all .5s ease;
    color: rgb(58 62 59);
    border: 3px solid rgb(58 62 59);
    font-family:'Montserrat', sans-serif;
    // text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: 0.9em;
	font-weight: bold;
    background-color : transparent;
    padding: 10px;
    outline: none;
    border-radius: 4px;
	cursor: pointer;
  }

  .startBtn:hover{
	color: rgb(245 251 239);
    background-color: rgb(58 62 59);
  }

  .personalized-box{
	top: 25%;
	@media only screen and (max-width : 620px){
		top:15%;
	  }
	
  }
  .text{
	height: 50px;
	width: 100%;
	padding: 0;
	font-size: 25px;
  }
  .breadcrumb {
	display: none !important;
  }
  .ct-logo {
    padding-top: 60px;
  }
  .sidebar.lhs {
	width: 30%;
	display: inline-block;
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 58%, rgb(155, 199, 229) 120%);    
	height: 100%;
	padding: 20px 50px;
	vertical-align: top;
	border-right: 1px solid rgba(221, 221, 221, 1);
	@media only screen and (max-width : 1000px){
		width: auto;
		padding: 20px 20px;
	}
  }
  .titleContent span {
	background-color: #3B8BFD;
	background-image: linear-gradient(125.8deg, #3B8BFD, #F06CB2);
	background-size: 100%;
	background-repeat: repeat;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
  }
  .lhs-content img, .lhs-content .desc, .lhs-content .titleContent, .lhs-content .cta-talk-to-us {
	display: block;
	margin-bottom: 40px;
	text-align: left;
	@media only screen and (max-width : 1000px){
		margin: 0 auto;
		margin-bottom: 25px;
		text-align: center;
	}
  }
  .cta-talk-to-us a, .c-btn {
	margin: 0;
	line-height: 2.2;
	padding: 12px 28px;
	background: #1076fb;
	border-radius: 6px;
	font-size: 16px;
	font-weight: 600;
	color: #fff;
    border: 0;
    line-height: 1.3;
	cursor: pointer;
	&.custom-flex-btn {
		align-self: flex-start;
	}

	@media only screen and (max-width : 1000px){
		padding: 12px 25px;
	}
  }
  .signedcall-img {
	text-align: center;
	display: block;
	margin: 0 auto;
  }
  .rhs {
	width: 70%;
	display: inline-block !important;    
	padding: 150px 40px 0 40px;
	text-align: left;
	vertical-align: top;
	position: relative;
    background: #fff;
	height: 100%;
	@media only screen and (max-width : 1000px){
		width: auto;
        padding: 40px 20px 40px 20px !important;
	}
	&.rhsSteps {
		padding-top: 80px;
	}
  }
  .rhs a img {
	border: 1px solid rgba(221, 221, 221, 1);
	border-radius: 8px;
	overflow: hidden;
	transition: 0.5s ease all;
	&:hover {
		transform: scale(1.05);
	}
  }
  .desc {
	font-size: 16px;
	font-weight: normal;
  }
  .titleContent {
	font-size: 45px;
	line-height: 1.35;
  }
  .rhs a:first-child, .rhs a:nth-child(1) {
	margin-left: 0;
  }
  .rhs {
	a {
		display: inline-block;
		min-width: 21%;
		text-align: left;
		font-size: 22px;
		font-weight: 600;
		@media only screen and (max-width : 1000px){
			min-width: auto;
			font-size: 19px;
			width: 48%;
			position: relative;
			margin: 0 0 20px 0;
			padding: 10px;
		}
	img {
		display: block;
		margin-bottom: 15px;
		border-radius: 8px;
		@media only screen and (max-width : 1000px){
			width: 100%;
		}
	  }
	}
	.cta-talk-to-us {
		margin-top: 20px;
		display: block;
		a {
			font-size: 16px;
			min-width: auto;
			padding: 6px 25px;
			@media only screen and (max-width : 1000px){
				padding: 6px 20px;
				width: auto;
			}
		}
	}
  }
  .pageTitle {
	font-size: 48px;
	font-weight: normal;
	margin-bottom: 40px;
	@media only screen and (max-width : 1000px){
		font-size: 25px;
        margin-bottom: 20px;
        margin-top: 15px;
	}
  }
  .parentperson .personalNav .col-md-4 {
    border: 1px solid rgba(221, 221, 221, 1);
    border-radius: 8px;
    margin-right: 25px;
    padding: 24px;
    min-width: 280px;
    max-width: 280px;
    text-align: left;
    align-self: flex-start;
    min-height: 335px;

	@media only screen and (max-width : 1000px){
		margin-right: 0;
		padding: 15px;
		min-width: auto;
		max-width: none;
		width: 100%;
		min-height: auto;
		margin-bottom: 10px;
	}
}
.recomTxtWrap {
    padding: 0;
	margin-top: 0;
	display: none;
	margin-bottom: 0;
    user-select: auto !important;
}
.recomTxtWrap li {
    user-select: auto !important;
}
.recomTxt {
	margin-top: 10px;
	list-style-type: none;
	cursor: pointer;
	list-style: none;
	border-radius: 20px;
	padding: 10px 10px;
	background: rgba(233, 245, 253, 1);
	color: rgba(52, 130, 183, 1);
}
.backToPrev {
	background: url(https://clevertap.com/wp-content/uploads/2024/04/back-arrow-app.svg) top left no-repeat;
	background-size: contain;
    display: inline-block;
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(16, 118, 251, 1);
    padding-left: 20px;
	cursor: pointer;
	@media only screen and (max-width : 1000px){
		left: 15px;
	}
	&.startOver {
		background: url(https://clevertap.com/wp-content/uploads/2024/04/Retry.svg) top left no-repeat;
		font-size: 16px;
		font-weight: 500;

		@media only screen and (max-width : 1000px){
			padding-left: 20px;
			padding-top: 0;
			vertical-align: top;
			left:0;
		}
	}
}

.personalNav p {
    margin-top: 0;
    font-size: 24px;
    margin-bottom: 30px;
}
.registerForm {
	fieldset {
		width: 100%;
		max-width: 650px !important;
	}
	.hs-form-field .hs-input {
		@media only screen and (max-width : 1000px){
			width: 100% !important;
		}
	}
	.hs_phone.hs-phone {
		position: relative;
		left: -255px;
		@media only screen and (max-width : 1000px){
			width: 100% !important;
			left: 0;
			.input input, select {
				border-radius: 8px !important;
			}
		}
		.input input, select {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.hs-error-msgs {
			margin-left: -70px;
			@media only screen and (max-width : 1000px){
				margin-left: 0px;
			}
		}
	}
	.inputLabel {
		margin-bottom: 25px;
	}
	.hs_error_rollup {
		display: none;
	}
	.hs-error-msgs li {
		line-height: 1;
		position: relative;
		top: -15px;
		margin-top: -5px;
	}
	.input, select {
		padding: 0;
		margin: 0 0 20px 0;
		border: 0;
		border-radius: 0;
		border-color: transparent;
		input, select {
			padding: 16px;
			border-radius: 8px;
			border: 1px solid rgba(19, 59, 88, 1);
			font-size: 15px;
			min-width: 300px;
		}
	}
	select {
		margin-bottom: 0 !important;
	}
	input.hs-button.primary {
		color: #fff;
		background: rgba(16, 118, 251, 1);
		border: 0;
		font-size: 15px;
		font-weight: 500;
		padding: 15px 25px;
		border-radius: 7px;
		margin-top: 20px;
		cursor: pointer;
	}
	.hs-error-msgs, .hs_error_rollup{
		list-style: none;
		margin-bottom: 0;
		margin-top: 0;
		padding-left: 0;
		li label {
			font-size: 15px;
			list-style: none;
			color: #f00;
		}
	}
}
.code-snippet-wrap {
	display: none;
}
.steps {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
	position: relative;
	&.step-1:after {
		content: '';
		position: absolute;
		left: 22px;
		height: 73%;
		top: 50px;
		background: #ccc;
		width: 2px;
		border-radius: 50px;
	}
	&.step-2:after {
		display: none;
	}
	.step-image {
		margin-right: 25px;
		@media only screen and (max-width : 1000px){
			margin-right: 15px;
		}
	}
	.step-content {
		background: rgba(247, 247, 248, 1);
		padding: 20px 25px;
		width: 100%;
    	max-width: 750px;
		border-radius: 10px;
		position: relative;
		.step-label {
			color: rgba(80, 89, 106, 1);
			font-size: 12px;
			font-weight: 400;
			margin-top: 0;		
		}
		.step-title {
			color: rgba(19, 59, 88, 1);
			font-size: 24px;
			font-weight: 500;
			margin: 0px;
			@media only screen and (max-width : 1000px){
				font-size: 20px;
			}
		}
		.step-desc {
			color: rgba(80, 89, 106, 1);
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 0;		
		}
	}
}
#sc-sdk {
	#sc__sdk {
		background: rgba(52, 130, 183, 1);
	}
	#sc__callactions {
		position: relative;
		top: -5px;
		#sc__mute_container p, #sc__ong--hangup--container p, #sc__og--cancel--container p {
			display: none !important;
		}
	}
	.sc__sdk--brand {
		margin-bottom: 110px;
	}
	#sc__sdk--timer {
		position: relative;
		top: -110px;
	}
	.bodypart {
		display: block;
		align-content: center;
		margin: 5px auto;
		font-family: "circular_stdbook";
		font-style: normal;
		font-weight: normal;
		text-align: center;
		letter-spacing: -0.01px;
		color: #000000;
		background: rgb(255, 255, 255);
		padding: 23px 12px 12px 12px;
		border-radius: 6px;
		box-shadow: 0px 3.2px 3.2px 0px rgba(0, 0, 0, 0.25);
		line-height: 1.3;
		font-size: 14px;
		width: 275px;
		position: relative;
		top: 35px;
		margin: 0 auto;
		left: -105%;
		word-wrap: break-word;
		&:nth-of-type(n+2) {
			display: none !important;
		}
		&:before {
			content: 'Call reason';
			position: absolute;
			right: 0;
			left: 0;
			margin: 0 auto;
			top: 5px;
			color: #858B97;
			font-size: 12px;
		}
	}
}
.device .device-frame img {
	max-width: 140px;
}
#imgUploadedPreview {
	max-width: 120px;
    margin: 0 auto;
    width: 120px;
    display: block;
}
.logo-wrap {
	background: rgba(247, 247, 248, 1);
	padding: 20px;
	.uploadImg {
		margin-bottom: 20px;
		padding-bottom: 20px;
		position: relative;
		&:before {
			content: '';
			background: rgba(221, 221, 221, 1);
			width: 80%;
			height: 1px;
			bottom: 0;
			right: 0;
			left: 0;
			margin: 0 auto;
			position: absolute;
		}
	}
}
.coverD {
	text-align: center;
	.d-block {
		padding: 0;
		border-radius: 6px;
		overflow: hidden;
	}
}
.colorbox {
	width: 130px;
    height: 45px;
    position: relative;
    border: 0;
	&:after {
		content: '';
		background: url(https://clevertap.com/wp-content/uploads/2024/04/pick-color.svg) center center no-repeat;
		width: 50px;
		position: absolute;
		right: -4px;
		top: 0;
		height: 100%;
	}
	&:hover{
		&:after {
			filter: brightness(0.9);
		}
	}
}
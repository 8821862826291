@import url("https://rsms.me/inter/inter.css");

* {
box-sizing: border-box;
}

html,
body,
#root {
width: 100%;
height: 100%;
margin: 0;
padding: 0;
-webkit-touch-callout: none;
-webkit-user-select: none;
-khtml-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
overflow: hidden;
}
pre {
white-space: pre-wrap;
}
body {
font-family: "Inter", sans-serif;
}

#root {
background: white;
background: radial-gradient(ellipse at -200% 60%, rgba(209, 209, 209, 0.48) 22%, rgba(182, 182, 182, 0) 71%),
  linear-gradient(160deg, rgb(233, 233, 233), hsl(0, 0%, 100%) 100%);
overflow: auto;
}

body {
position: fixed;
overflow: hidden;
overscroll-behavior-y: none;
font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
  sans-serif;
-webkit-font-smoothing: antialiased;
}

a {
pointer-events: all;
cursor: pointer;
color: inherit;
text-decoration: none;
margin-left: 20px;
}



.receiver{
display: flex;
width: 500px;
height: 100%;
background-color: #dc2d2e;
color: white;
border: double;
margin: 0 auto;
align-items: center;
justify-content: center;
white-space: pre;
margin-bottom: 2.5rem;
font-size: 3.5em;
font-weight: 700;
letter-spacing: -4px;
}
.receiverlink{
margin-left: -30px;
white-space: pre;
margin-bottom: -11.5rem;
font-size: 5em;
font-weight: 800;
letter-spacing: -4px;
}
.d-block{
padding: 5px;
background: #fff;
border-radius: 1px;
box-shadow: 0 0 0 1px rgba(0,0,0,.1);
display: inline-block;
cursor: pointer;
}
.cover{
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px
}

/* .copy-text{
  position: relative;
  padding: 10px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
}

.copy-text input.text{
  padding: 10px;
  font-size: 18px;
  color: #555;
  border: none;
  outline: none;
}

.copy-text button{
  padding: 10px;
  background: #5784f5;
  color: #fff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
.copy-text button:active{
  background: #809ce2;
}
.copy-text button::before{
  content: "Copied";
  position: absolute;
  top: -45px;
  right: 0px;
  background: #5c81dc;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 15px;
  display: none;
}

.copy-text button::after{
  content: "";
  position: absolute;
  top: -20px;
  right: 25px;
  width: 10px;
  height: 10px;
  background: #5c81dc;
  transform: rotate(45deg);
  display: none;
}
.copy-text.active button::before,
.copy-text.active button::after{
  display: block;
} */